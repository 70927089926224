<template>

  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" heading="Alle abbonomenten"/>
    <div>
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <material-card
        class="mt-6 elevation-1 mt-16"
        icon="fa-regular fa-credit-card-blank"
        icon-small
        title="alle Abonnementen"
        color="accent"
      >
        <v-data-table
          loading-text="Laden even geduld aub"
          :headers="headers"
          @click:row="goToSubscription"
          :items="subscriptions"
          :items-per-page="-1"
        ></v-data-table>
      </material-card>

      <div class="mt-8">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Subscriptions',
    data () {
      this.$auth.getAbonnementen().then(async subscriptions => {
        const subscriptionsWithUsers = []
        for (const subscription of subscriptions) {
          subscriptionsWithUsers.push({
            ...subscription,
          })
        }
        this.subscriptions = this.$filter.filterSubscriptions(subscriptionsWithUsers)
      }).catch(err => {
        this.alert.visible = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        headers: [
          {
            text: 'Op naam',
            value: 'userName',
          },
          {
            text: 'Naam',
            value: 'name',
          },
          {
            text: 'Korting',
            value: 'presentage',
          },
          {
            text: 'Gelig tot',
            value: 'endDate',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
        subscriptions: [],
        loading: true,
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
      }
    },
    methods: {
      goToSubscription (subscription) {
        const { id } = subscription
        this.$router.push(`/subscriptions/${id}`)
      },
    },
  }
</script>

<style scoped>

</style>
